@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "SF Pro";
  src: url("../../public/fonts/CashSans-Regular.woff2") format("woff2"),
    url("../../public/fonts/CashSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro";
  src: url("../../public/fonts/CashSansWide-Semibold.woff2") format("woff2"),
    url("../../public/fonts/CashSansWide-Semibold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: expanded;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("../../public/fonts/CashSans-Regular.woff2") format("woff2"),
    url("../../public/fonts/CashSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("../../public/fonts/CashSans-Semibold.woff2") format("woff2"),
    url("../../public/fonts/CashSans-Semibold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../../public/fonts/CashSans-Bold.woff2") format("woff2")
    url("../../public/fonts/CashSans-Bold.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

.expanded-bold {
  font-family: "SF Pro";
  font-weight: 700;
  font-stretch: expanded;
}

.regular {
  font-family: "SF Pro Text";
  font-weight: 400;
}

.bold {
  font-family: "SF Pro Text";
  font-weight: 700;
}

.heavy {
  font-family: "SF Pro Display";
  font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "SF Pro Text";
  font-weight: 400;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  font-family: "SF Pro Display";
  font-weight: 900;
}

body {
  @apply bg-black text-white;
  font-family: "SF Pro Text";
  font-weight: 400;
}

strong {
  font-family: "SF Pro Display";
  font-weight: 700;
}

/* New styles for video player sliders */
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    margin-top: -7px; /* Adjust this value to vertically center the thumb */
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: none;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    height: 0px;
    background: #4D4D4D;
  }
  
  input[type="range"]::-moz-range-track {
    height: 0px;
    background: #4D4D4D;
  }
